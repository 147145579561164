import Axios from 'axios';
import { parseAsync } from 'json2csv';
import tokml from 'tokml';
import Immutable from 'immutable'
import AppConfig from 'components/App/AppConfig';

/**
 * Trigers the download of the file referenced by URL
 *
 * @export
 * @param {string} fileName File Name
 * @param {string} url File URL
 */
export function downloadFile(fileName, url) {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;
  downloadLink.download = fileName;
  document.body.appendChild(downloadLink); // required for firefox
  downloadLink.click();
  document.body.removeChild(downloadLink);
}

export function downloadAsJson(exportName, url) {
  Axios.get(url).then((response) => {
    downloadFile(`${exportName}.json`, `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(response.data))}`);
  });
}

function toKmlAndStyle( geojson ){
	let kml = tokml(geojson);
	const randomColor = `${Math.floor(Math.random() * 16777215).toString(16)}`;	
	let style = '';
	style = '<Style>';
	style += '<PolyStyle>';
	style += '<fill>';
	style += '0';
	style += '</fill>';
	style += '<outline>';
	style += '1';
	style += '</outline>';
	style += '</PolyStyle>';
	style += '<LineStyle>';
	style += '<color>';
	style += 'ff'+randomColor;
	style += '</color>';
	style += '<width>';
	style += '2';
	style += '</width>';
	style += '</LineStyle>';
	style += '</Style>';
	
	return kml.replaceAll('</Placemark>', style + '</Placemark>');
}

export function downloadAsKml(exportName, url) {
  Axios.get(url).then((response) => {
    downloadFile(`${exportName}.kml`, `data:text/kml;charset=utf-8,${encodeURIComponent(toKmlAndStyle(response.data))}`);
  });
}

export function downloadAsFileWithExt(exportName, tifAuxXmlContent, fileExt) {
  downloadFile(`${exportName}.${fileExt}`, `data:text/${fileExt};charset=utf-8,${encodeURIComponent(tifAuxXmlContent)}`);
}

export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;
}


/**
 *
 *
 * @export
 * @param {*} dataToConvert Accepts both JSON arrays/objects and readable streams
 * @param {*} options {@link www.npmjs.com/package/json2csv}
 * @returns {Promise} Promise object represents the csv blob url
 */
export function convertToCSV(dataToConvert, options) {
  return new Promise((resolve, reject) => {
    parseAsync(dataToConvert, (options ? { ...options } : null))
      .then((csv) => resolve(URL.createObjectURL(new window.Blob(['', csv]))))
      .catch((err) => { reject(err); });
  });
}

export function componentLoader(lazyComponent, attemptsLeft = 4, interval = 1500) {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject,
          );
        }, interval);
      });
  });
}

export function translateField(field, language) {
	
  if( language && language.length > 2 ){ // in cases like es-AR ( agentinian spanish)
	  language = language.substring( 0,2 ); // then it becomes es
  }
  if (field != null && typeof field === 'object') {
    if (field.get !== undefined) {
      if (field.get(language)) {
        return field.get(language);
      } if (field.get( AppConfig.DEFAULT_LOCALE )) {
        return field.get( AppConfig.DEFAULT_LOCALE );
      }
      return field.get(0);
    }
    if (field[language]) {
      return field[language];
    } if (field.en) {
      return field.en;
    }
    return field[0];
  }
  return field;
}

export function convertImmutableToJS(immutableData) {
  if (Immutable.Map.isMap(immutableData)) {
      const obj = {};
      immutableData.forEach((value, key) => {
          obj[key] = convertImmutableToJS(value);
      });
      return obj;
  } else if (Immutable.List.isList(immutableData)) {
      return immutableData.map(convertImmutableToJS).toArray();
  } else {
      return immutableData;
  }
}

// see https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export function RGB_Log_Blend(p,c0,c1){
    var i=parseInt,r=Math.round,P=1-p,[a,b,c,d]=c0.split(","),[e,f,g,h]=c1.split(","),x=d||h,j=x?","+(!d?h:!h?d:r((parseFloat(d)*P+parseFloat(h)*p)*1000)/1000+")"):")";
    return"rgb"+(x?"a(":"(")+r((P*i(a[3]=="a"?a.slice(5):a.slice(4))**2+p*i(e[3]=="a"?e.slice(5):e.slice(4))**2)**0.5)+","+r((P*i(b)**2+p*i(f)**2)**0.5)+","+r((P*i(c)**2+p*i(g)**2)**0.5)+j;
}

// see https://github.com/PimpTrizkit/PJs/wiki/12.-Shade,-Blend-and-Convert-a-Web-Color-(pSBC.js)
export function RGB_Log_Shade(p,c){
    var i=parseInt,r=Math.round,[a,b,c,d]=c.split(","),P=p<0,t=P?0:p*255**2,P=P?1+p:1-p;
    return"rgb"+(d?"a(":"(")+r((P*i(a[3]=="a"?a.slice(5):a.slice(4))**2+t)**0.5)+","+r((P*i(b)**2+t)**0.5)+","+r((P*i(c)**2+t)**0.5)+(d?","+d:")");
}

export function RGBAToHexA(rgba, forceRemoveAlpha = false) {
  return "#" + rgba.replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
    .split(',') // splits them at ","
    .filter((string, index) => !forceRemoveAlpha || index !== 3)
    .map(string => parseFloat(string)) // Converts them to numbers
    .map((number, index) => index === 3 ? Math.round(number * 255) : number) // Converts alpha to 255 number
    .map(number => number.toString(16)) // Converts numbers to hex
    .map(string => string.length === 1 ? "0" + string : string) // Adds 0 when length of one number is 1
    .join("") // Puts the array to togehter to a string
}
