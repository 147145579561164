import { createSelector } from 'reselect';

export const getSelectedBoundary = createSelector(
  (state) => state.boundaries.get('selectedBoundaryId'),
  (state) => state.user.get('boundaries'),
  (state) => state.boundaries.get('boundaries'),
  (state) => state.boundaries.get('tempBoundaries'),
  (selectedBoundaryId, userBoundaries, boundaries, tempBoundaries) => {
    let selectedBoundary;
    if (selectedBoundaryId) {
      if (userBoundaries) {
        selectedBoundary = userBoundaries.get(selectedBoundaryId);
        if (selectedBoundary) return selectedBoundary;
      }
      if (boundaries) {
        selectedBoundary = boundaries.get(selectedBoundaryId);
        if (selectedBoundary) return selectedBoundary;
      }
      if (tempBoundaries) {
        selectedBoundary = tempBoundaries.get(selectedBoundaryId);
        if (selectedBoundary) return selectedBoundary;
      }
    }
    return selectedBoundary;
  },
);

export const getBoundaries = createSelector(
  (state) => state.boundaries.get('boundaries'),
  ( boundaries) => boundaries,
);

export const getTempBoundaries = createSelector(
  (state) => state.boundaries.get('tempBoundaries'),
  (tempBoundaries) => tempBoundaries,
);

export const getSelectedBoundaryId = createSelector(
  (state) => state.boundaries.get('selectedBoundaryId'),
  (selectedBoundaryId) => selectedBoundaryId,
);

export const getSelectedSecondaryBoundariesIds = createSelector(
  (state) => state.boundaries.get('selectedSecondaryBoundariesIds'),
  (selectedSecondaryBoundariesIds) => selectedSecondaryBoundariesIds,
);

export const getTempPolygon = createSelector(
  (state) => state.boundaries.get('tempPolygon'),
  (tempPolygon) => tempPolygon,
);

export const getTempBoundariesName = createSelector(
  (state) => state.boundaries.get('tempBoundariesName'),
  (tempBoundariesName) => tempBoundariesName,
);
