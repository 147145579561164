import { createSelector } from 'reselect';

export const getAvailableLanguages = createSelector(
  (state) => state.app.get('availableLanguages'),
  (availableLanguages) => (
    availableLanguages
  ),
);

export const getSelectedLanguage = createSelector(
  (state) => state.app.get('selectedLanguage'),
  (selectedLanguage) => (
    selectedLanguage
  ),
);
