import { fromJS } from 'immutable';

import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
  loading: false,
  layers: {},
  layersGroups: null,
  modalLayerInfoId: null,
});

const loadLayersStart = (state) => state.merge({
  loading: true,
});

const loadLayersSuccess = (state, action) => state.merge({
  layers: fromJS(action.layers),
  layersGroups: fromJS(action.layersGroups),
  loading: false,
});

const showModalLayerInfo = (state, action) => state.merge({
  modalLayerInfoId: action.layerId,
});

const layersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_LAYERS_START:
      return loadLayersStart(state);
    case actionTypes.LOAD_LAYERS_SUCCESS:
      return loadLayersSuccess(state, action);
    case actionTypes.SHOW_MODAL_LAYER_INFO:
      return showModalLayerInfo(state, action);
    default:
      return state;
  }
};

export default layersReducer;
