import { fromJS } from 'immutable';

import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
  loading: false,
  scriptsInfo: {},
  preSelectedScripts: [],
  selectedTab: undefined,
  drawerOpen: false,
});

const loadScriptsInfoStart = (state) => state.merge({
  loading: true,
});

const loadScriptsInfoSuccess = (state, action) => state.merge({
  loading: false,
  scriptsInfo: fromJS(action.scriptsInfo),
});

const loadPreSelectedScripts = (state, action) => state.merge({
  preSelectedScripts: action.preSelectedScripts,
});

const drawerOpen = (state, action) => state.merge({
  drawerOpen: action.drawerOpen,
});

const selectTab = (state, action) => state.merge({
  selectedTab: action.selectedTab,
});

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_SCRIPTS_INFO_SUCCESS:
      return loadScriptsInfoSuccess(state, action);
    case actionTypes.LOAD_SCRIPTS_INFO_START:
      return loadScriptsInfoStart(state);
    case actionTypes.LOAD_PRESELECTEDSCRIPTS:
      return loadPreSelectedScripts(state, action);
    case actionTypes.STATISTICS_DRAWER_OPEN:
      return drawerOpen(state, action);
    case actionTypes.SELECT_TAB:
      return selectTab(state, action);
    default:
      return state;
  }
};

export default statisticsReducer;
