import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/storage';
import axios from 'axios';

var customizationObjects = [];

function init() {
	const firebaseConfig = JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG);
	firebase.initializeApp(firebaseConfig);
}

function signOut() {
	return new Promise((resolve, reject) => {
		firebase.auth().signOut().then(() => {
			resolve();
		}).catch((error) => {
			reject(error);
		});
	});
}

function getAvailableLanguages() {
	return new Promise((resolve, reject) => {
		firebase.database().ref('locale').once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

/*
  This function is used to get the user's token to be used in the API requests
  It returns a promise that resolves with the token or rejects with an error
*/
   
function getUserAPIBearer() {
    return new Promise((resolve, reject) => {
        const user = firebase.auth().currentUser;
        if (user) {
            resolve(user.refreshToken); // Returns the API Bearer ( User refresh token )
        } else {
            reject('No user is currently authenticated');
        }
    });
}

function getTranslations(locale) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`translations/${locale}`).once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getAreasList() {
	return new Promise((resolve, reject) => {
		firebase.database().ref('areasList').once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getArea(areaId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`areas/${areaId}`).once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getCustomization(customizationName) {
	if (customizationObjects[customizationName])
		return Promise.resolve(customizationObjects[customizationName]);
	else {
		return new Promise((resolve, reject) => {
			firebase.database().ref(`customizations/${customizationName}`).once('value').then((snapshot) => {
				customizationObjects[customizationName] = snapshot.val();
				resolve(customizationObjects[customizationName]);
			})
				.catch((error) => {
					reject(error);
				});
		});
	}
}

function getBoundaries(areaId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`boundaries/${areaId}`).once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getLayer(layerId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`layers/${layerId}`).once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getLayers() {
	return new Promise((resolve, reject) => {
		firebase.database().ref('layers').once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getLayersGroups() {
	return new Promise((resolve, reject) => {
		firebase.database().ref('layersGroups').once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getScriptsMetadata() {
	return new Promise((resolve, reject) => {
		firebase.database().ref('scripts').once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getScript(scriptId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`scripts/${scriptId}`).once('value').then((snapshot) => {
			resolve(snapshot.val());
		})
			.catch((error) => {
				reject(error);
			});
	});
}

function getDownloadUrl(path) {
	return new Promise((resolve, reject) => {
		firebase.storage().ref(path).getDownloadURL()
			.then((url) => {
				resolve(url);
			})
			.catch((error) => {
				reject(error);
			});
	});
}

// to get the user 
function addUserBoundary(userId, boundaryName, geoJson, areaId, agStackId) {
	return new Promise((resolve, reject) => {
		const storage = firebase.storage();
		const storageRef = storage.ref();
		const boundaryId = firebase.database().ref(`/users/${userId}/boundaries/`).push().key;
		const boundaryRef = `users/${userId}/boundaries/${boundaryId}.geojson`;
		const userRef = storageRef.child(boundaryRef);
		const file = new Blob([JSON.stringify(geoJson)], { type: 'application/json' });
		userRef.put(file).then(() => {
			resolve({ boundaryId, geojsonSrc: boundaryRef });
			const boundary = {
				name: boundaryName,
				geojsonSrc: boundaryRef,
				areaId,
				agStackId : agStackId? agStackId : ''
			};
			const updates = {};
			updates[`/users/${userId}/boundaries/${boundaryId}`] = boundary;
			firebase.database().ref().update(updates)
				.catch((error) => {
					reject(error);
				});
		}).catch((error) => {
			reject(error);
		});
	});
}

function loadUserBoundaries(userId, areaId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`users/${userId}/boundaries`)
			.orderByChild('areaId')
			.equalTo(areaId)
			.once('value')
			.then((snapshot) => {
				resolve(snapshot.val());
			})
			.catch((error) => {
				reject(error);
			});
	});
}

function deleteUserBoundary(userId, boundaryId) {
	return new Promise((resolve, reject) => {
		// TODO: CHECK IF SHOULD DESLECT BOUNDARY
		const storage = firebase.storage();
		const storageRef = storage.ref();
		const userRef = storageRef.child(`users/${userId}/boundaries/${boundaryId}.geojson`);
		userRef.delete().then(() => {
			firebase.database().ref(`/users/${userId}/boundaries/${boundaryId}`).remove().then(() => {
				resolve();
			})
				.catch((error) => {
					reject(error);
				});
		}).catch((error) => {
			reject(error);
		});
	});
}

function setUserLocale(userId, locale) {
	firebase.database().ref(`users/${userId}`).set({
		locale,
	}).catch((error) => { throw error; });
}

function setUserOptions(userId, options) {
	return firebase.database().ref(`users/${userId}/config/userOptions`).set(options)
		.catch((error) => { throw error; });
}

function getUserOptions(userId) {
	return new Promise((resolve, reject) => {
		firebase.database().ref(`users/${userId}/config/userOptions`)
			.once('value')
			.then((snapshot) => {
				resolve(snapshot.val());
			})
			.catch((error) => {
				reject(error);
			});

	});
}

function doNotShowSplashDialogAgain(userId) {
	firebase.database().ref(`users/${userId}/config/showSplashDialog/${process.env.REACT_APP_CUSTOMIZATION_NAME}`).set(
		false
	).catch((error) => { throw error; });
}

function shouldShowSplashDialog(userId) {
	if (userId === null || userId === undefined) {
		return true;
	}
	
	return new Promise((resolve, reject) => {
		firebase.database().ref(`users/${userId}/config/showSplashDialog/${process.env.REACT_APP_CUSTOMIZATION_NAME}`)
			.once('value')
			.then((snapshot) => {
				const value = snapshot.val();
				// Check if the value is null, which indicates the option or config folder does not exist
				if (value === null) {
					resolve(true); // By default show the splash dialog if there is one in the sutomization!
				} else {
					resolve(value);
				}
			})
			.catch((error) => {
				reject(error);
			});

	});
}

async function getGeoJson(boundaryId, abortController) {
	try {
		const url = await firebase.storage().ref(boundaryId).getDownloadURL();
		// Pass the cancelToken to the axios request
		const response = await axios.get(url, { signal: abortController.signal });
		return response.data;
	} catch (error) {
		// Handle cancellation error
		if (axios.isCancel(error)) {
			console.warn('Request canceled', error.message);
		} else {
			// Handle other errors
			console.log('Error doanloading GeoJSON', error.message);
			throw error;
		}
	}
}

async function getCustomizationConfig() {
	if (process.env.REACT_APP_CUSTOM_CONFIG) {
		return JSON.parse(process.env.REACT_APP_CUSTOM_CONFIG);
	} else if (process.env.REACT_APP_CUSTOMIZATION_NAME) {
		return await getCustomization(process.env.REACT_APP_CUSTOMIZATION_NAME);
	}
	return undefined;
}

export default {
	addUserBoundary,
	deleteUserBoundary,
	getArea,
	getAreasList,
	getAvailableLanguages,
	getBoundaries,
	getCustomizationConfig,
	getDownloadUrl,
	getGeoJson,
	getLayer,
	getLayers,
	getLayersGroups,
	getScript,
	getScriptsMetadata,
	getTranslations,
	init,
	loadUserBoundaries,
	setUserLocale,
	signOut,
	doNotShowSplashDialogAgain,
	shouldShowSplashDialog,
	getUserOptions,
	setUserOptions,
	getUserAPIBearer
};
