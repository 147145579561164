import { fromJS } from 'immutable';

import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
  loading: false,
  areas: {},
  areaList: null,
  selectedAreaId: null,
});

const loadAreasStart = (state) => state.merge({
  loading: true,
});

const loadAreasSuccess = (state, action) => state.merge({
  areaList: fromJS(action.areas),
  loading: false,
});

const loadAreaLayersStart = (state) => state.merge({
  loading: true,
});

const loadAreaLayersSuccess = (state, action) => {
  const { areaData, areaId } = action;
  const areas = state.get('areas');
  return state.merge({
    areas: areas.set(areaId, fromJS(areaData)),
    selectedAreaId: action.areaId,
    loading: false,
  });
};

const areasReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOAD_AREAS_START:
      return loadAreasStart(state);
    case actionTypes.LOAD_AREAS_SUCCESS:
      return loadAreasSuccess(state, action);
    case actionTypes.LOAD_AREA_LAYERS_START:
      return loadAreaLayersStart(state);
    case actionTypes.LOAD_AREA_LAYERS_SUCCESS:
      return loadAreaLayersSuccess(state, action);
    default:
      return state;
  }
};

export default areasReducer;
