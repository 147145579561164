import * as actionTypes from 'store/actions/actionTypes';

const markerAdd = (marker) => ({
  type: actionTypes.ADD_MARKER,
  marker,
});

const markerRemove = (markerId) => ({
  type: actionTypes.REMOVE_MARKER,
  markerId
});

export const addMarker = (marker) => (dispatch) => {
  dispatch(markerAdd(marker));
};

export const removeMarker = (markerId) => (dispatch) => {
  dispatch(markerRemove(markerId));
};
