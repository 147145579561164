import { fromJS } from 'immutable';

import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
	loading: false,
	boundaries: null,
  tempBoundaries: null,
  tempBoundariesName: null,
	selectedBoundaryId: null,
  selectedSecondaryBoundariesIds: [],
	tempPolygon: null,
});

const loadBoundariesStart = (state) => state.merge({
	loading: true,
});

const loadBoundariesSuccess = (state, action) => state.merge({
		boundaries: fromJS(action.boundaries),
		loading: false,
	});

const selectBoundary = (state, action) => state.merge({
	selectedBoundaryId: action.boundaryId,
});

const addSecondaryBoundary = (state, action) => state.merge({
  selectedSecondaryBoundariesIds: state.get('selectedSecondaryBoundariesIds').concat(action.boundaryId),
});

const removeSecondaryBoundary = (state, action) => state.merge({
  selectedSecondaryBoundariesIds: state.get('selectedSecondaryBoundariesIds').filter(id => id !== action.boundaryId)
});

const resetSecondaryBoundaries = (state) => state.merge({
  selectedSecondaryBoundariesIds: []
});

const addTempBoundary = (state, action) => {
  const boundaryId = action.name || new Date().getTime();

  let tempBoundaries = state.get('tempBoundaries');
  if (!tempBoundaries) { tempBoundaries = fromJS({}); }
  tempBoundaries = tempBoundaries.set(boundaryId, fromJS({
    name: action.name,
    geoJson: action.boundary,
  }));
  return state.set('tempBoundaries', tempBoundaries);
}

const addBoundary = (state, action) => {
	const boundaryId = action.name || new Date().getTime();
	let boundaries = state.get('boundaries');
	if( !boundaries ) boundaries =fromJS( {} );
	return state.merge({
		boundaries: boundaries.set(boundaryId, fromJS({ name: action.name, geoJson: action.boundary, agStackId: action.agStackId })),
		selectedBoundaryId: boundaryId,
	});
};

const addTempPolygon = (state, action) => state.merge({
	tempPolygon: fromJS(action.polygon),
});

const setTempBoundariesName = (state, action) => state.merge({
  tempBoundariesName: action.name,
});

const boundariesReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOAD_BOUNDARIES_START:
			return loadBoundariesStart(state);
		case actionTypes.LOAD_BOUNDARIES_SUCCESS:
			return loadBoundariesSuccess(state, action);
		case actionTypes.SELECT_BOUNDARY:
			return selectBoundary(state, action);
    case actionTypes.ADD_TEMP_BOUNDARY:
      return addTempBoundary(state, action);
    case actionTypes.ADD_SECONDARY_BOUNDARY:
      return addSecondaryBoundary(state, action);
    case actionTypes.REMOVE_SECONDARY_BOUNDARY:
      return removeSecondaryBoundary(state, action);
    case actionTypes.RESET_SECONDARY_BOUNDARIES:
      return resetSecondaryBoundaries(state);
		case actionTypes.ADD_BOUNDARY:
			return addBoundary(state, action);
		case actionTypes.ADD_TEMP_POLYGON:
			return addTempPolygon(state, action);
    case actionTypes.SET_TEMP_BOUNDARIES_NAME:
      return setTempBoundariesName(state, action);
		default:
			return state;
	}
};

export default boundariesReducer;
