import { fromJS } from 'immutable';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
  loading: true,
  authenticated: false,
  boundaries: {},
  areas: {},
  user: null,
});

const authenticateSuccess = (state, options) => state.merge({
  authenticated: true,
  user: fromJS(options.user),
  loading: false,
});

const authenticateStart = (state) => state.merge({
  loading: true,
});

const logout = () => initialState.merge({
  loading: false,
});

const loadUserBoundariesSuccess = (state, action) => {
  if (action.boundaries) { return state.merge({ boundaries: fromJS(action.boundaries) }); }
  return state.merge({ boundaries: fromJS({}) });
};

const addBoundary = (state, action) => {
  const boundaries = state.get('boundaries');
  return state.merge({
    boundaries: boundaries.set(action.boundaryId, fromJS({
      geoJson: action.geoJson,
      name: action.boundaryName,
      geojsonSrc: action.geojsonSrc,
      areaId: action.areaId,
      agStackId: action.agStackId,
    })),
  });
};

const deleteBoundary = (state, action) => {
  const boundaries = state.get('boundaries');
  return state.merge({
    boundaries: boundaries.delete(action.boundaryId),
  });
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTHENTICATE_SUCCESS:
      return authenticateSuccess(state, action);
    case actionTypes.AUTHENTICATE_START:
      return authenticateStart(state);
    case actionTypes.USER_LOGOUT:
      return logout(state);
    case actionTypes.USER_LOAD_BOUNDARIES_SUCCESS:
      return loadUserBoundariesSuccess(state, action);
    case actionTypes.USER_ADD_BOUNDARY:
      return addBoundary(state, action);
    case actionTypes.USER_DELETE_BOUNDARY:
      return deleteBoundary(state, action);
    default:
      return state;
  }
};

export default userReducer;
