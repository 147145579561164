import firebase from 'api/firebase/firebase';
import * as actionTypes from 'store/actions/actionTypes';
import { pushURLQuery } from 'router/UrlQueries';
import * as urlQueryFields from 'router/urlQueryFields';

const boundariesLoadStart = () => ({
  type: actionTypes.LOAD_BOUNDARIES_START,
});

const boundariesLoadSuccess = (boundaries) => ({
  type: actionTypes.LOAD_BOUNDARIES_SUCCESS,
  boundaries,
});

const boundarySelect = (boundaryId) => ({
  type: actionTypes.SELECT_BOUNDARY,
  boundaryId,
});

const tempBoundaryAdd = (name, boundary) => ({
  type: actionTypes.ADD_TEMP_BOUNDARY,
  name,
  boundary,
});

const secondaryBoundaryAdd = (boundaryId) => ({
  type: actionTypes.ADD_SECONDARY_BOUNDARY,
  boundaryId,
});

const secondaryBoundaryRemove = (boundaryId) => ({
  type: actionTypes.REMOVE_SECONDARY_BOUNDARY,
  boundaryId,
});

const secondaryBoundariesReset = () => ({
  type: actionTypes.RESET_SECONDARY_BOUNDARIES,
});

const boundaryAdd = (name, boundary) => ({
  type: actionTypes.ADD_BOUNDARY,
  name,
  boundary,
});

const tempPolygonAdd = (name, polygon) => ({
  type: actionTypes.ADD_TEMP_POLYGON,
  name,
  polygon,
});

const tempBoundariesNameSet = (name) => ({
  type: actionTypes.SET_TEMP_BOUNDARIES_NAME,
  name,
});


export const loadBoundaries = (areaId) => (dispatch) => {
  dispatch(boundariesLoadStart());
  firebase.getBoundaries(areaId).then((areaBoundaries) => {
    dispatch(boundariesLoadSuccess(areaBoundaries));
  });
};

export const selectBoundaryId = (boundaryId) => (dispatch) => {
  pushURLQuery(urlQueryFields.boundary, boundaryId);
  dispatch(boundarySelect(boundaryId));
};

export const addTempBoundary = (name, boundary) => (dispatch) => {
  dispatch(tempBoundaryAdd(name, boundary));
};

export const addSecondaryBoundary = (boundaryId) => (dispatch) => {
  // TODO: url query
  dispatch(secondaryBoundaryAdd(boundaryId));
};

export const removeSecondaryBoundary = (boundaryId) => (dispatch) => {
  // TODO: url query
  dispatch(secondaryBoundaryRemove(boundaryId));
};

export const resetSecondaryBoundaries = () => (dispatch) => {
  // TODO: url query
  dispatch(secondaryBoundariesReset());
}

export const addBoundary = (name, boundary) => (dispatch) => {
  dispatch(boundaryAdd(name, boundary));
};

export const addTempPolygon = (name, polygon) => (dispatch) => {
  dispatch(tempPolygonAdd(name, polygon));
};

export const setTempBoundariesName = (name) => (dispatch) => {
  dispatch(tempBoundariesNameSet(name));
};
