import { SET_IS_MOBILE } from '../actions/actionTypes';

const initialState = {
  isMobile: window.innerWidth <= 960, // Set initial state based on window size
};

const uiReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      };
    default:
      return state;
  }
};

export default uiReducer;