import { useState, useEffect } from 'react';
import firebase from 'api/firebase/firebase';

const useCustomConfig = function() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      const customConfiguration = await firebase.getCustomizationConfig();
      setConfig(customConfiguration);
    };
    fetchConfig();
  }, []);

  return config;
}

export default useCustomConfig;