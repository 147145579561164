import { createSelector } from 'reselect';
import LayerObject from 'utils/LayerObject';
import { getSelectedAoi } from 'store/selectors/areas';

export const selectLayers = createSelector(
	getSelectedAoi,
	(state) => state.layers.get('layers'),
	(state) => state.app.get('selectedLanguage'),
	(selectedAoi, immutableLayers, selectedLanguage) => {
		const layers = new Map();
		if (selectedAoi) {
			immutableLayers.forEach((immutableLayer, layerId) => {
				const layerFromAoi = selectedAoi.get('layers').find((element) => element.get('id') === layerId);
				if (layerFromAoi) {
					layers.set(
						layerId,
						new LayerObject(
							immutableLayer
								.set('id', layerId)
								.set('src', layerFromAoi.get('src') ? layerFromAoi.get('src') : immutableLayer.get('src') )
								.set('type', layerFromAoi.get('type') )
								.set('useLegendVisualization', layerFromAoi.get('useLegendVisualization'))
								.set('visualizeParams', layerFromAoi.get('visualizeParams'))
								.set('year', layerFromAoi.get('year')),
							selectedLanguage,
						)
					);
				} else { layers.set(layerId, new LayerObject(immutableLayers.set('id', layerId), selectedLanguage)); }
			});
		}
		return layers;
	},
);

export const selectGroupedAreaLayers = createSelector(
	selectLayers,
	(state) => state.layers.get('layersGroups'),
	(state) => state.areas.get('areas'),
	(state) => state.areas.get('selectedAreaId'),
	(layers, layersGroups, areas, selectedAreaId) => {
		if (selectedAreaId && layers.size > 0) {
			try {
				const groupedLayers = new Map();
				const areaLayers = areas.getIn([selectedAreaId, 'layers']);
				areaLayers.forEach((areaLayer) => {
					try {
						const layer = layers.get(areaLayer.get('id'));
						if (!layer) throw new Error(`Layer ${areaLayer.id} does not exist, ${areaLayer}`);
						const groupId = layer.getGroupId();
						if (!groupedLayers.has(groupId)) {
							try {
								const groupIcon = layersGroups.get(groupId).get('icon');
								const name = layersGroups.get(groupId).get('name');
								const position = layersGroups.get(groupId).get('position');
								groupedLayers.set(groupId, { groupIcon, name, position });
							} catch (error) { throw new Error(`${error} ${groupId}`); }
						}
						const group = groupedLayers.get(groupId);
						layer.customizationLayer = areaLayer.get('customizationLayer');
						if (group.layers) { group.layers.push(layer); } else { (group.layers = [layer]); }
					} catch (error) {
						console.error(`Error loading layers ${areaLayer} : ${error} `);
						console.error(error);
					}
				});
				return groupedLayers;
			} catch (error) {
				throw new Error(`Error loading layers${error}`);
			}
		} else { return null; }
	},
);
