import ReactGA from 'react-ga4';

const GOOGLE_ANALYTICS_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
// eslint-disable-next-line no-var
var initialized = false;
class Analytics {
  
  
  // eslint-disable-next-line class-methods-use-this
  initGA() {
    if (!initialized) {
      if (GOOGLE_ANALYTICS_ID && GOOGLE_ANALYTICS_ID !== 'YOUR_API_KEY') {
        ReactGA.initialize(
          GOOGLE_ANALYTICS_ID,
          { gtagOptions: { anonymize_ip: true } },
        );
      } else {
        throw (new Error('No Google Analyticis ID available in the .env file'));
      }
      return true;
    }
    return false;
  }

  logPageView() {
	this.initGA()
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
  }

  logEvent(category = '', action = '') {
	this.initGA()
    if (category && action) {
      ReactGA.event({ category, action });
    }
  }
}

export default Analytics;
