import { createSelector } from 'reselect';

export const getOrderedUserBoundaries = createSelector(
  (state) => state.user.get('boundaries'),
  (userBoundaries) => userBoundaries.sort((a, b) => a.get('name').localeCompare(b.get('name'))),
);

export const getUserId = createSelector(
  (state) => state.user.getIn(['user', 'uid']),
  (userId) => userId,
);

export const getUserEmail = createSelector(
  (state) => state.user.getIn(['user', 'email']),
  (userEmail) => userEmail,
);
