import firebase from 'api/firebase/firebase';
import * as actionTypes from 'store/actions/actionTypes';
import AgStack from 'utils/AgStackAPI';
import { toast } from "react-toastify"

const authenticateStart = () => ({
	type: actionTypes.AUTHENTICATE_START,
});

const authenticateSuccess = (user) => ({
	type: actionTypes.AUTHENTICATE_SUCCESS,
	user,
});

const signout = () => ({
	type: actionTypes.USER_LOGOUT,
});

const userBoundariesLoadSuccess = (boundaries) => ({
	type: actionTypes.USER_LOAD_BOUNDARIES_SUCCESS,
	boundaries,
});

const agStack = new AgStack();

const userBoundaryAdd = (boundaryId, geoJson, boundaryName, geojsonSrc, areaId, agStackId) => ({
	type: actionTypes.USER_ADD_BOUNDARY,
	boundaryId,
	geoJson,
	boundaryName,
	geojsonSrc,
	areaId,
	agStackId,
});

const userBoundarydelete = (boundaryId) => ({
	type: actionTypes.USER_DELETE_BOUNDARY,
	boundaryId,
});

const boundarySelect = (boundaryId) => ({
	type: actionTypes.SELECT_BOUNDARY,
	boundaryId,
});

const authenticated = (dispatch, user) => {
	const {
		uid, email, language, displayName,
	} = user;
	dispatch(authenticateSuccess(
		{
			uid, email, language, displayName,
		},
	));
};

export const authenticate = (authResponse) => (dispatch) => {
	dispatch(authenticateStart());
	authenticated(dispatch, authResponse);
};

export const logout = () => (dispatch) => {
	firebase.signOut().then(() => {
		dispatch(signout());
	});
};

function sendToFirebase(dispatch, userId, boundaryName, geoJson, areaId, agStackId) {
	firebase.addUserBoundary(userId, boundaryName, geoJson, areaId, agStackId)
		.then(({ boundaryId, geojsonSrc }) => {
			dispatch(userBoundaryAdd(boundaryId, geoJson, boundaryName, geojsonSrc, areaId, agStackId));
			dispatch(boundarySelect(boundaryId));
		});
}

export const addUserBoundary = (userId, boundaryName, geoJson, areaId, config) => (dispatch) => {
	if (config && config.linkedServices && config.linkedServices.agStackGeoIDEnabled) {

		// Check if the user has Opted-In to register the boundary with AgStack

		firebase.getUserOptions(userId)
			.then(options => {
				if (options && options.agStackOptIn && options.agStackOptIn === true) {

		agStack.registerGeoJson(geoJson, true)
			.then(
				(agStackResponse) => {
					if (agStackResponse && agStackResponse.errorMessage) {

						toast.warn(
							"AgStack message : " + agStackResponse.errorMessage,
							{ autoClose: 5000 }
						);
								}else{
									toast.success (
										"AgStack : Polygon added to registry",
										{ autoClose: 5000 }
									);
					}
					sendToFirebase(dispatch, userId, boundaryName, geoJson, areaId, agStackResponse);
				})
			.catch(
				(error) => {
					console.log('error', error);
					sendToFirebase(dispatch, userId, boundaryName, geoJson, areaId);
				}
			);


				} else {
					sendToFirebase(dispatch, userId, boundaryName, geoJson, areaId);
				}
			})
			.catch(error => {
				console.error('Failed to fetch user options', error);
			});

	} else {
		sendToFirebase(dispatch, userId, boundaryName, geoJson, areaId);
	}
};

export const deleteUserBoundary = (userId, boundaryId) => (dispatch) => {
	firebase.deleteUserBoundary(userId, boundaryId);
	dispatch(userBoundarydelete(boundaryId));
};

export const loadUserBoundaries = (userId, areaId) => (dispatch) => {
	firebase.loadUserBoundaries(userId, areaId).then((boundaries) => {
		dispatch(userBoundariesLoadSuccess(boundaries));
	});
};
