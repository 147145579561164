import * as actionTypes from 'store/actions/actionTypes';

const initialState = {
  markers: [],
};

const addMarker = (state, action) => {
  return {
    ...state,
    markers: [...state.markers, action.marker],
  };
};

const removeMarker = (state, action) => {
  return {
    ...state,
    markers: state.markers.filter((marker) => marker.id !== action.markerId),
  };
};
  
export const markerReducer = (state = initialState, action) => {
  switch (action.type){
    case actionTypes.ADD_MARKER:
      return addMarker(state, action);
    case actionTypes.REMOVE_MARKER:
      return removeMarker(state, action);
    default:
      return state;
  }
};

export default markerReducer;
