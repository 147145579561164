import { createSelector } from 'reselect';
import { getSelectedAoi } from 'store/selectors/index';

export const getScripts = createSelector(
  (state) => getSelectedAoi(state),
  (state) => state.statistics.get('scriptsInfo'),
  (selectedArea, scriptsInfo) => {
    if (scriptsInfo && selectedArea && selectedArea.get('scripts')) {
      const aoiScripts = selectedArea.get('scripts').toArray();
      return scriptsInfo.filter((value, scriptId) => aoiScripts.includes(scriptId));
    }
    return scriptsInfo;
  },
);

export const getPreSelectedScripts = createSelector(
  (state) => state.statistics.get('preSelectedScripts'),
  (preSelectedScripts) => preSelectedScripts,
);

export const getStatisticsDrawerOpen = createSelector(
  (state) => state.statistics.get('drawerOpen'),
  (drawerOpen) => drawerOpen,
);

export const getSelectedTab = createSelector(
  state => state.statistics.get('selectedTab'),
  selectedTab => selectedTab,
)
