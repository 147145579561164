import { createSelector } from 'reselect';

export const isLoading = createSelector(
  (state) => state.layers.get('loading'),
  (state) => state.areas.get('loading'),
  (state) => state.boundaries.get('loading'),
  (state) => state.statistics.get('loading'),
  (state) => state.user.get('loading'),
  (layers, areas, boundaries, statistics, user) => (
    layers || areas || boundaries || statistics || user
  ),
);

export const getMainMenuOpen = createSelector(
  (state) => state.dashboard.get('mainMenuOpen'),
  (mainMenuOpen) => (
    mainMenuOpen
  ),
);
