export function getFeatureName(properties) {
  const STANDARD_NAMES = ['adm2_name', 'adm2nm', 'adm1_name', 'adm1nm','adm0_name', 'name', 'nombre', 'nom', 'romnam', 'admin_name', 'admin'];
  const propertiesArray = Object.getOwnPropertyNames(properties);
  const possibleNames = propertiesArray
    .map(((property) => property.toLowerCase()))
    .filter((propertie) => STANDARD_NAMES.includes(propertie))
    .sort((a, b) => STANDARD_NAMES.indexOf(a) - STANDARD_NAMES.indexOf(b));
  return properties[propertiesArray.find(
    (property) => property.toLowerCase() === possibleNames[0],
  )];
}

export function getBoundaryInfo(boundary) {
  if (boundary && boundary.properties) {
    const featureName = getFeatureName(boundary.properties);
    const { population } = boundary.properties;
    const elevation = boundary.properties.avg_elevation;
    const { area } = boundary.properties;
    return {
      name: featureName, area, population, elevation,
    };
  }
  return {};
}