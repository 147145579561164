import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { getSelectedLanguage } from 'store/selectors/index';
import * as actions from 'store/actions/index';
import useCustomConfig from 'hooks/useConfig';
import messages from 'translations/messages_en.json';

function SwappingIntlProvider({ children }) {
  const dispatch = useDispatch();
  const selectedLanguage = useSelector(getSelectedLanguage);
  const config = useCustomConfig();
  const [translations, setTranslations] = useState(messages);

  // Update language if a default language is available o app config
  useEffect(() => {
    if (config && config.ui && config.ui.language) { // If there is a specific language set on the configuration for the environment, use that one (for the use case of North Macedonia)
      dispatch(actions.setSelectedLanguage(config.ui.language));
    }
  }, [config, dispatch]);
  
  
  // Update language if a new language is selected
  useEffect(() => {
	  let selectedCleanLanguage = selectedLanguage;
	  if(selectedCleanLanguage.length > 2 ){
		  selectedCleanLanguage = selectedCleanLanguage.substring(0,2);
	  }
	  
	import(`translations/messages_${  selectedCleanLanguage }.json`)
  		.then(translationMessages => setTranslations(translationMessages) )
  		.catch(() => { console.warn( `Translations for ${  selectedCleanLanguage  } not found. Using default messages.`, setTranslations(messages) ) } );
  }, [selectedLanguage]);

  return (
    <IntlProvider locale={selectedLanguage} messages={translations}>
      {children}
    </IntlProvider>
  );
}

SwappingIntlProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default SwappingIntlProvider;
