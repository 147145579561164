import { fromJS } from 'immutable';
import * as actionTypes from 'store/actions/actionTypes';

const initialState = fromJS({
  showModalAbout: false,
  showModalDisclaimer: false,
  mainMenuOpen: false, // TODO --> fix ths, false actually makes the dialog open on login, true hides it!!
});

const showModalDisclaimer = (state, action) => state.merge({
  showModalDisclaimer: action.enable,
});

const toggleMenuDrawer = (state, action) => state.merge({ mainMenuOpen: action.open });

const dashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MODAL_DISCLAIMER:
      return showModalDisclaimer(state, action);
    case actionTypes.DRAWER_MENU_TOGGLE:
      return toggleMenuDrawer(state, action);
    default:
      return state;
  }
};

export default dashboardReducer;
