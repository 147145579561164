function extractFeatures(geometry, features, properties) {
	if (geometry.type === 'Polygon') {
		features.push({
			type: 'Feature',
			properties: properties || {},
			geometry: {
				type: 'Polygon',
				coordinates: geometry.coordinates.map(ring =>
					ring.map(coord => coord.slice(0, 2))
				)
			}
		});
	} else if (geometry.type === 'Point') {
		features.push({
			type: 'Feature',
			properties: properties || {},
			geometry: {
				type: 'Point',
				coordinates: geometry.coordinates.slice(0, 2)
			}
		});
	} else if (geometry.type === 'MultiPolygon') {

		features.push({
			type: 'Feature',
			properties: properties || {},
			geometry: {
				type: 'MultiPolygon',
				coordinates: geometry.coordinates.map(polygon =>
					polygon.map(ring =>
						ring.map(coord => coord.slice(0, 2))
					)
				)
			}
		});
	} else if (geometry.type === 'GeometryCollection') {
		let index = 0;
		geometry.geometries.forEach(geom => {
			extractFeatures(geom, features, { name: properties.name + ' ' + geom.type + " " + index++ });
		});
	} else if (geometry.type === 'Feature') {
		extractFeatures(geometry.geometry, features, geometry.properties);
	} else if (geometry.type === 'FeatureCollection') {
		geometry.features.forEach(feature => {
			extractFeatures(feature, features);
		});
	}
}

export function createFeatureCollectionFromJSON(geojson) {
	let features = [];
	extractFeatures(geojson, features);
	let name = geojson.name ? geojson.name : null;
	return {
		type: 'FeatureCollection',
		features: features,
		name: name,
	};
}