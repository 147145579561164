import firebase from 'api/firebase/firebase';
import * as actionTypes from 'store/actions/actionTypes';
import { pushURLQuery } from 'router/UrlQueries';
import * as urlQueryFields from 'router/urlQueryFields';

const scriptsInfoLoadSuccess = (scriptsInfo) => ({
  type: actionTypes.LOAD_SCRIPTS_INFO_SUCCESS,
  scriptsInfo,
});

const scriptsInfoLoadStart = () => ({
  type: actionTypes.LOAD_SCRIPTS_INFO_START,
});

const preSelectedScriptsLoad = (preSelectedScripts) => ({
  type: actionTypes.LOAD_PRESELECTEDSCRIPTS,
  preSelectedScripts,
});

const statisticsDrawerOpen = (open) => ({
  type: actionTypes.STATISTICS_DRAWER_OPEN,
  drawerOpen: open,
});

export const loadScriptsInfo = () => async (dispatch) => {
  dispatch(scriptsInfoLoadStart());
  const scriptsMetadata = await firebase.getScriptsMetadata();
  dispatch(scriptsInfoLoadSuccess(scriptsMetadata));
};

export const loadPreselectedScripts = (scriptsIds) => (dispatch) => {
  dispatch(preSelectedScriptsLoad(scriptsIds));
};

// TODO: remove this method - obsolete
export const openStatisticsDrawer = (open) => (dispatch) => {
  pushURLQuery(urlQueryFields.statisticsOpen, open);
  dispatch(statisticsDrawerOpen(open));
};

export const selectTab = (selectedTab) => (dispatch) => {
  // TODO: also add to url query
  dispatch({
    type: actionTypes.SELECT_TAB,
    selectedTab: selectedTab,
  })
}
