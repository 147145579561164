import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import appReducer from 'store/reducers/app';
import areasReducer from 'store/reducers/areas';
import boundariesReducer from 'store/reducers/boundaries';
import dashboardReducer from 'store/reducers/dashboard';
import layersReducer from 'store/reducers/layers';
import mapreducer from 'store/reducers/map';
import markerReducer from 'store/reducers/markers';
import statisticsReducer from 'store/reducers/statistics';
import userReducer from 'store/reducers/user';
import uiReducer from 'store/reducers/ui';


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  areas: areasReducer,
  layers: layersReducer,
  map: mapreducer,
  markers: markerReducer,
  dashboard: dashboardReducer,
  statistics: statisticsReducer,
  boundaries: boundariesReducer,
  user: userReducer,
  app: appReducer,
  ui: uiReducer,
});

export default createRootReducer;