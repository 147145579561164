import { fromJS } from 'immutable';
import * as actionTypes from 'store/actions/actionTypes';
import AppConfig from 'components/App/AppConfig';

let languageUI = AppConfig.DEFAULT_LOCALE ; // Default to English
// Get the browser language
const userLanguage = navigator.language || navigator.userLanguage;
if (userLanguage) {
  languageUI = userLanguage;
}

const initialState = fromJS({
  availableLanguages: [],
  selectedLanguage: languageUI,
});

const setAvailableLanguages = (state, action) => {
  const result = Object.keys(action.availableLanguages).map(
    (languageId) => ({ id: languageId, language: action.availableLanguages[languageId] }),
  );
  return state.merge({
    availableLanguages: fromJS(result),
  });
};

const setSelectedLanguage = (state, action) => state.merge({
  selectedLanguage: fromJS(action.selectedLanguage),
});

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AVAILABLE_LANGUAGES:
      return setAvailableLanguages(state, action);
    case actionTypes.SET_SELECTED_LANGUAGE:
      return setSelectedLanguage(state, action);
    default:
      return state;
  }
};

export default appReducer;
