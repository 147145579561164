//const email = process.env.REACT_APP_AGSTACK['email'];
const agStackCredentials = process.env.REACT_APP_AGSTACK;
const { geojsonToWKT } = require('@terraformer/wkt');

class AgStackAPI {
	constructor() {
		this.assetUrl = 'https://api-ar.agstack.org';
		this.userUrl = 'https://user-registry.agstack.org';
		this.sessionCookie = null;
	}

	// get the polygon from the asset GeoID . It is not necessary to authenticate to get the polygon
	async getGeoJSON(geoId) {
		const response = await fetch(`${this.assetUrl}/fetch-field/${geoId}`, {
			method: 'GET',
		});
		const json = await response.json()
		if (json && json['Geo JSON'] !== undefined) {
			return json['Geo JSON'];
		} else {
			throw new Error('GeoJSON not found ' + geoId);
		}
	}


	async getAgstackToken() {
		if (agStackCredentials === undefined) {
			console.warn("AgStack credentials not found, skipping AgStack geoId registration. Please set the REACT_APP_AGSTACK environment variable.");
			return null;
		}
		/**
		 * Authenticate with the AgStack API and obtain a token.
		 *
		 * Parameters:
		 * - email: The email for authentication.
		 * - password: The password for authentication.
		 * - assetRegistryBase: The base URL of the asset registry. Default is the AgStack API base URL.
		 *
		 * Returns:
		 * - token: The access token obtained after successful authentication.
		 */

		// Define the authentication endpoint
		const authUrl = `${this.assetUrl}/login`;
		try {
			// Make a POST request to authenticate and obtain the token
			const response = await fetch(authUrl, {
				method: 'POST',
				headers: {
					'Accept': 'application/json',
					'Content-Type': 'application/json'
				},
				body: agStackCredentials
			});

			// Check if the authentication was successful
			if (response.ok) {
				// Extract the token from the response
				const data = await response.json();
				const token = data['access_token'];
				return token;
			} else {
				// Log the error if authentication failed
				console.log('Authentication failed. Status code:', response.status);
				console.log('Error message:', await response.text());
				return null; // Return null to indicate failure
			}
		} catch (error) {
			// Log any network error
			console.error('Network error:', error.message);
			return null;
		}
	}


	async registerGeoJson(geoJson, debug = false) {
		if (agStackCredentials === undefined) {
			console.warn("AgStack credentials not found, skipping AgStack geoId registration. Please set the REACT_APP_AGSTACK environment variable.");
			return null;
		}
		/**
		 * Registers a field boundary with the given WKT using the AgStack API.
		 *
		 * Parameters:
		 * - wkt: The Well-Known Text (WKT) representation.
		 * - token: The authentication token required by the API.
		 * - session: Optional parameter. If provided, the function will use the existing session for the request.
		 *
		 * Returns:
		 * - The Geo Id or matched Geo Ids for the registered field boundary.
		 */
		
		if (geoJson.type === undefined && geoJson.geometry === undefined) {
			throw new Error('Invalid GeoJSON');
		}
		
		if (geoJson.type === "FeatureCollection") {
			// Get the first feature in the collection
			console.warn("GeoJSON is a feature collection. Using the first feature in the collection as AgStack takes a single WKT (Polygon/Point/etc...).")
			geoJson = geoJson.features[0];
		}
		
		// Define the request payload
		const payload = {
			wkt: geojsonToWKT(geoJson.geometry)
		};

		// Make the POST request
		const url = `${this.assetUrl}/register-field-boundary`;

		// Get the token
		const token = await this.getAgstackToken();
		const headers = token ? { 'Authorization': `Bearer ${token}` } : {};

		let response = await fetch(url, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				...headers
			},
			body: JSON.stringify(payload)
		});
		if (debug) console.log("Using individual request as no existing session set up");

		// Process the response
		if (response.ok) {
			const jsonResponse = await response.json();
			console.log("Response:", jsonResponse);
			let res = jsonResponse['Geo Id'];
			if( res ) console.log("Field boundary registered successfully!");
			else{
				console.warn("Failed to register plot", jsonResponse);
				res = {};
				res.errorMessage = jsonResponse.message;
			}
			return res;
		} else {
			const jsonResponse = await response.json();
			const res = jsonResponse["matched geo ids"] ? jsonResponse["matched geo ids"][0] : null;
			if (res) {
				if (debug) console.log("Matched existing field (failed to register). Status code:", response.status, "Using pre-existing geo id: ", res);
			} else {
				console.log("Failed to register field boundary (no geo id returned). Status code:", response.status);
				console.log("Error message:", jsonResponse);
				res.errorMessage = jsonResponse.message;
			}
			return res;
		}
	}

}

export default AgStackAPI;

/*
// test the class above
const api = new AgStackAPI();
console.log("Test the class above");

	api.getGeoJSON('88bec54ad04804f5b1fafbc131266640a129be2840fa6797cda358d7e831b907').then( geoJson => {
		console.log("Polygon fetched succesfully", geoJson );
	}).catch(err => {
		console.log("Error Getting Polygon", err);
	})
*/