import firebase from 'api/firebase/firebase';
import * as actionTypes from 'store/actions/actionTypes';

const layersLoadStart = () => ({
  type: actionTypes.LOAD_LAYERS_START,
});

const layersLoadSuccess = (layers, layersGroups) => ({
  type: actionTypes.LOAD_LAYERS_SUCCESS,
  layers,
  layersGroups,
});

const modalLayerInfoShow = (layerId) => ({
  type: actionTypes.SHOW_MODAL_LAYER_INFO,
  layerId,
});

export const loadLayers = () => (dispatch) => {
  dispatch(layersLoadStart());
  firebase.getLayers().then((layers) => {
    firebase.getLayersGroups().then((layersGroups) => {
      dispatch(layersLoadSuccess(layers, layersGroups));
    });
  });
};

export const showModalLayerInfo = (modalLayerInfoId) => (dispatch) => {
  dispatch(modalLayerInfoShow(modalLayerInfoId));
};
