import firebase from 'api/firebase/firebase';
import * as actionTypes from 'store/actions/actionTypes';

const AvailableLanguagesSet = (availableLanguages) => ({
	type: actionTypes.SET_AVAILABLE_LANGUAGES,
	availableLanguages,
});

const selectedLanguageSet = (selectedLanguage) => ({
	type: actionTypes.SET_SELECTED_LANGUAGE,
	selectedLanguage,
});

export const loadAvailableLanguages = () => (dispatch) => {
	firebase.getAvailableLanguages().then((availableLanguages) => {
		// Now that we have all of the possible languages lets filter for only the ones that are in the config (if specified)
		firebase.getCustomizationConfig().then((config) => {
			try {

				/*
				// Show only the locales that are in the config (if present)
				function isContainedInConfig(languageAvailable) {
					return config.ui.showLocales.includes(languageAvailable.get('id'));
				}
				availableLanguages.filter(isContainedInConfig);
				*/

				// Show only the locales that are in the config (if present)
				function isContainedInConfig(languageAvailable) {
					return config.ui.showLocales.includes(languageAvailable.id);
				}

				if (config && config.ui && config.ui.showLocales && config.ui.showLocales.length !== 0) {

					// Convert the JSON object to an array of objects
					const availableLanguagesArray = Object.entries(availableLanguages).map(([id, name]) => ({ id, name }));

					// Filter the array based on the configuration
					const filteredLanguagesArray = availableLanguagesArray.filter(isContainedInConfig);

					// Convert the filtered array back to a JSON object (if needed)
					availableLanguages = filteredLanguagesArray.reduce((obj, item) => {
						obj[item.id] = item.name;
						return obj;
					}, {});
				}

			} catch (error) {
				// Handle any errors that occur during the filtering process
				console.error("An error occurred while filtering languages:", error);
			}

			dispatch(AvailableLanguagesSet(availableLanguages));
		}).catch((error) => {
			console.error('Error getting customization config:', error);
			dispatch(AvailableLanguagesSet(availableLanguages));
		});
	}).catch((error) => {
		console.error('Error getting available languages:', error);
	});
};

export const setSelectedLanguage = (selectedLanguage) => (dispatch) => {
	dispatch(selectedLanguageSet(selectedLanguage));
};
