import App from 'components/App/App';
import SwappingIntlProvider from 'components/UI/SwappingIntlProvider/SwappingIntlProvider';
import { ConnectedRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import React from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from '@sentry/react';
import { CaptureConsole } from '@sentry/integrations';
import { Integrations } from '@sentry/tracing';
import { Provider } from 'react-redux';
import { Route } from 'react-router-dom';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxThunk from 'redux-thunk';
import createRootReducer from 'store/reducers/index';
import './index.css';
import firebase from 'api/firebase/firebase';
import { unregister } from './registerServiceWorker';
import packageInfo from '../package.json'


// Redux DevTools
// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const history = createBrowserHistory();
firebase.init();

const store = createStore(
	createRootReducer(history),
	composeEnhancers(applyMiddleware(reduxThunk, routerMiddleware(history))),
);

if (window.location.hostname !== 'localhost') {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_CLIENT_ID,
		// Set tracesSampleRate to 1.0 to capture 100%
		// of transactions for performance monitoring.
		// We recommend adjusting this value in production
		tracesSampleRate: 0.2,
		// If the entire session is not sampled, use the below sample rate to sample
		// sessions when an error occurs.
		replaysOnErrorSampleRate: 0.2,
		debug: false,
		integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
		release: packageInfo.version,
		ignoreErrors: [
			'Non-Error exception captured',
			'Non-Error promise rejection captured',
		],
		// Capture console errors!
		integrations: [
			new CaptureConsole({
				levels: ['error']
			})
		],
	});
}

const app = (
	<Provider store={store}>
		<SwappingIntlProvider>
			<ConnectedRouter history={history}>
				<Route>
					<App />
				</Route>
			</ConnectedRouter>
		</SwappingIntlProvider>
	</Provider>
);

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);
// Initial render: Render the App component to the root
root.render(app);

unregister();

export default store;